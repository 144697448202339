import { BrowserModule } from "@angular/platform-browser";
import {
    NgModule,
    APP_INITIALIZER,
    ErrorHandler,
    ApplicationRef
} from "@angular/core";
import { Configuration } from "./app.constants";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ApiHttpService } from "./shared/services/api-http.service";
import { MessageService } from "./shared/services/message.service";
import { NavigationService } from "./shared/services/navigation.service";
import { DxDataGridModule, DxValidationGroupModule } from "devextreme-angular";
import { Http, URLSearchParams } from "@angular/http";
import {
    HttpClientModule,
    HttpClient,
    HTTP_INTERCEPTORS
} from "@angular/common/http";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ToastrModule } from "ngx-toastr";
import { NgHttpLoaderModule } from "ng-http-loader";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
    AuthModule,
    OidcSecurityService,
    OpenIDImplicitFlowConfiguration,
    OidcConfigService,
    AuthWellKnownEndpoints
} from "angular-auth-oidc-client";
//import { AuthService } from './shared/services/auth.service';
import { JwtInterceptor } from "./shared/services/jwt-interceptor";
import { AuthenticationGuard } from "./shared/services/authentication-guard";
import { AuthenticationService } from "./shared/services/authentication.service";

import { LoginModule } from "./login/login.module";
import { ShowYesNoDirective } from "./shared/directives/show-yes-no.directive";
import { GlobalErrorHandlerService } from "./shared/services/global-error-handler.service";
import { ClipboardModule } from "ngx-clipboard";
import { environment } from "../environments/environment";
//import { NpDatepickerModule } from 'angular-nepali-datepicker';
import { NgxTinymceModule } from "ngx-tinymce";


//// AoT requires an exported function for factories
//export function createTranslateLoader(http: HttpClient) {
//    // for development
//    // return new TranslateHttpLoader(http, '/start-angular/SB-Admin-BS4-Angular-5/master/dist/assets/i18n/', '.json');
//    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
//}

@NgModule({
    declarations: [AppComponent, ShowYesNoDirective],
    imports: [
        BrowserModule.withServerTransition({ appId: "ng-cli-universal" }),
        AppRoutingModule,
        HttpClientModule,
        DxDataGridModule,
        CommonModule,
        ReactiveFormsModule,
        NgHttpLoaderModule.forRoot(),
        FormsModule,
        ToastrModule.forRoot({
            closeButton: true,
            positionClass: "toast-top-center",
            easing: "ease-in",
            easeTime: 500,
            enableHtml: true,
            timeOut: 1500,
            extendedTimeOut: 1000
        }),
        BrowserAnimationsModule,
        AuthModule.forRoot(),
        DxValidationGroupModule,
        ClipboardModule,        
        NgxTinymceModule.forRoot({
            baseURL: "./assets/tinymce/"
        })
    ],
    providers: [
        ApiHttpService,
        MessageService,
        NavigationService,
        AuthenticationGuard,
        AuthenticationService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true
        },
        Configuration
    ],
    exports: [ShowYesNoDirective],
    bootstrap: [AppComponent]
})
export class AppModule {}
