// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    //production: true,
    //apiUrl: "https://sms-api.theideators.net/api/",
    //rootUrl: "https://sms-api.theideators.net/",
    //identityUrl: "http://hbapi.dev.itmagnet.biz/api/client-application/admin-application-angular-client"

    production: true,
    apiUrl: "https://bdcg-api.theideators.net/api/",
    rootUrl: "https://bdcg-api.theideators.net/",
    identityUrl: "http://hbapi.dev.itmagnet.biz/api/client-application/admin-application-angular-client"

    // production: false,
    // apiUrl: "https://localhost:7154/api/",
    // rootUrl: "https://localhost:7154/",
    // identityUrl: "https://localhost:7154/api/client-application/admin-application-angular-client"

    //production: false,
    //apiUrl: "http://api.servicemanager.com/api/",
    //rootUrl: "http://api.servicemanager.com/",
    //identityUrl: "http://api.servicemanager.com/api/client-application/admin-application-angular-client"
};
